@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  box-sizing: border-box;
}

img,
svg {
  vertical-align: middle;
}
*::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
*::-webkit-scrollbar-track:horizontal {
  background: white;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
*::-webkit-scrollbar-track:vertical {
  background: white;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

*::-webkit-scrollbar-thumb {
  background: transparent;
  box-shadow: inset 0 0 100px #999999;
  border: 2px solid transparent;
  border-radius: 32px;
}

// Basic font-size for REM units
html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family:
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    'Noto Sans',
    'Liberation Sans',
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
}
